// order.service.js

export const createOrder = async (orderDetails) => {

  try {

    const token = localStorage.getItem('token');

    const response = await fetch('https://wafhostingapp.com/api/order/vds', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`  
      },
			
      body: JSON.stringify({
        tarif: orderDetails.tariffId,
        os: orderDetails.osId   
      })
    });

    if(!response.ok) {

      if(response.status === 400) {

        const errorData = await response.json(); 

        throw new Error(errorData.message);

      }

      throw new Error('Ошибка создания заказа'); 

    }

    return await response.json();

  } catch (error) {
    
    throw error;
  
  }  

};