// LocaleText.js

import { useSelector } from 'react-redux';

import { getLang } from '../store/selectors/selectors';

const LocaleText = ({id}) => {

  const lang = useSelector(getLang);

  const translations = lang === 'ru' 
    ? require('../config/locale/ru.json')
    : require('../config/locale/en.json');

  const keys = id.split('.');
  let text = translations;

  for (let key of keys) {
    text = text[key];
  }

  return text;

};

export default LocaleText;