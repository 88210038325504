// language.service.js

export async function changeLanguage(lang) {

  const token = localStorage.getItem('token');

  const response = await fetch(`https://wafhostingapp.com/api/language/${lang}`, {
    method: 'POST',
    headers: {
      Authorization: `${token}`  
    }
  });

  if(!response.ok) {
    throw new Error('Не удалось изменить язык');  
  }
  
  return response;

}