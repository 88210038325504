// reducer.js

import { SET_USER_DATA } from '../actions/user';

const initialState = {
  user: null   
};

export default function userReducer(state = initialState, action) {
  
  switch (action.type) {
    
    case SET_USER_DATA:

      console.log('SET_USER_DATA received');
      console.log('Saving data to state', action.payload);  

      return {
        ...state,
        user: action.payload
      };

    default:
      return state;
  }

}