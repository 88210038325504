export async function activatePromo(promoCode) {

  try {

    const token = localStorage.getItem('token');

    const response = await fetch('https://wafhostingapp.com/api/promocode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`
      },
      body: JSON.stringify({code: promoCode})
    });

    if(!response.ok) {
      const data = await response.json(); 
      throw data;
    }

    return response.json();

  } catch(error) {
    
    console.error(error.message || 'Неизвестная ошибка');
    throw error;

  }

}