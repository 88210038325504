// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/SF-Pro-Text-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/SF-Pro-Text-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/SF-Pro-Text-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/SF-Pro-Text-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/SF-Pro-Text-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/fonts/SF-Pro-Text-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./assets/fonts/SF-Pro-Text-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./assets/fonts/SF-Pro-Text-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff");
}
body {
  background-color: var(--tg-theme-secondary-bg-color);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/_fonts.scss","webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,oHAAA;ACCF;ADGA;EACE,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,oHAAA;ACDF;ADKA;EACE,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,oHAAA;ACHF;ADOA;EACE,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,oHAAA;ACLF;AArBA;EACC,oDAAA;AAuBD","sourcesContent":["@font-face {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 300;\n  src: url('../fonts/SF-Pro-Text-Light.woff2') format('woff2'),\n       url('../fonts/SF-Pro-Text-Light.woff') format('woff');\n}\n\n@font-face {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  src: url('../fonts/SF-Pro-Text-Regular.woff2') format('woff2'),\n       url('../fonts/SF-Pro-Text-Regular.woff') format('woff');\n}\n\n@font-face {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 500;\n  src: url('../fonts/SF-Pro-Text-Medium.woff2') format('woff2'),\n       url('../fonts/SF-Pro-Text-Medium.woff') format('woff');\n}\n\n@font-face {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 700;\n  src: url('../fonts/SF-Pro-Text-Bold.woff2') format('woff2'),\n       url('../fonts/SF-Pro-Text-Bold.woff') format('woff');\n}","@import 'assets/styles/fonts';\n\nbody {\n\tbackground-color: var(--tg-theme-secondary-bg-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
