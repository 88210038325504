// Home.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import svg4everybody from 'svg4everybody';
import './Home.scss';
import AuthService from '../../services/AuthService';
import { getBalance } from '../../services/balance.service';
import { getActive } from '../../services/active.service';
import { getUser } from '../../services/user.service';

import LocaleText from '../../utils/LocaleText';

svg4everybody();

const Home = () => {

  // Состояние для хранения баланса //
  const [balance, setBalance] = useState(localStorage.getItem('balance') || 0); // Исходное значение устанавливаем 0  

  // Состояние для хранения количества активных серверов //
  const [activeServers, setActiveServers]= useState(localStorage.getItem('vds') || 0); // Исходное значение устанавливаем 0 

  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser()
      .then(data => {
        setUser(data);
      }) 
  }, []);

  useEffect(() => {

    if (AuthService.isAuth) {
      
      setTimeout(() => {
        
        getBalance().then(res => {
          setBalance(res.balance)
          localStorage.setItem('balance', res.balance)
        });
    
        getActive().then(res => {
          setActiveServers(res.vds);
          localStorage.setItem('vds', res.vds)
        });
  
      }, 1000); // задержка в 1 секунду

    }
  }, []);
  
  useEffect(() => {
  
    if(balance && balance.length > 6) {
      document.querySelector(".profile").style.width = "117px";
    }
  
  }, [balance]);

  return (
    <div className="home-container">
      <div className="dashboard-welcome">
      {user && (
      <h1>
        <LocaleText id="welcomeUser"/>
        <span className="welcome__username">
          {user.first_name}
        </span>
      </h1>
      )}
    </div>
    <div className="dashboard-container">
      <Link to="/BuyServer" className="block buy-server">
        <span className="block-text">
          <LocaleText id="homePage.buyServer"/>
        </span>
        <svg  xmlns="http://www.w3.org/2000/svg" width="88" height="100" viewBox="0 0 88 100" fill="none">        
          <g filter="url(#filter0_i_2_1305)">
            <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M4.73227 0.0764106C2.59001 -0.345582 0.506032 1.02279 0.0775803 3.13275C-0.350872 5.24271 1.03844 7.29526 3.1807 7.71725L8.71534 8.80749C11.5569 9.36723 13.6942 11.6888 13.9825 14.5287L14.7923 22.5045L20.0111 65.3388C20.8859 72.5187 27.07 77.9224 34.4121 77.9224H81.8435C92.1294 77.9224 101.095 71.0275 103.59 61.1992L109.512 37.8693C112.05 27.8693 104.371 18.1825 93.9055 18.1825H22.3044L21.8547 13.7534C21.2204 7.50552 16.5183 2.39807 10.2669 1.16665L4.73227 0.0764106Z" fill="#2B3F6C"/>
            <path d="M61.973 58.4412H40.8758" stroke="#2B3F6C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <ellipse cx="38.2394" cy="92.2081" rx="7.91144" ry="7.79216" fill="#2B3F6C"/>
          </g>
        <defs>
          <filter id="filter0_i_2_1305" x="0%" y="0%" width="100%" height="100%" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_1305"/>
          </filter>
        </defs>
       </svg>
      </Link>


      <Link to="/Profile" className="block profile">
        <span className="block-text">
          <LocaleText id="homePage.profile"/>
        </span>
        <span className="profile-balance">{balance}₽</span> {/* Добавлено отображение баланса */}
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="97" viewBox="0 0 50 97" fill="none">
            <g filter="url(#filter0_i_48_3044)">
              <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M24.3517 66.1846C40.9376 59.4512 59.0624 59.4512 75.6483 66.1846C80.6567 68.2179 84 73.6106 84 79.6561V86.1072C84 94.5601 77.4138 101.053 70.0523 99.8578L68.3616 99.5832C56.1824 97.6055 43.8176 97.6055 31.6384 99.5832L29.9477 99.8578C22.5862 101.053 16 94.5601 16 86.1071V79.6561C16 73.6106 19.3433 68.2179 24.3517 66.1846Z" fill="#2B3F6C"/>
              <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M71.0579 30.937C71.0579 17.717 61.63 7 50 7C38.3701 7 28.9421 17.717 28.9421 30.937C28.9421 44.1571 38.3701 54.8741 50 54.8741C61.63 54.8741 71.0579 44.1571 71.0579 30.937Z" fill="#172136"/>
            </g>
              <defs>
                <filter id="filter0_i_48_3044" x="16" y="7" width="68" height="97" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_48_3044"/>
                </filter>
              </defs>
          </svg>  
      </Link>

      <Link to="/Information" className="block information">
        <span className="block-text">
          <LocaleText id="homePage.information"/>
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" width="81" height="71" viewBox="0 0 81 71" fill="none">
            <g filter="url(#filter0_i_48_3040)">
              <path opacity="0.3" d="M98.4696 12.6277C99.7861 8.87968 98.541 5.22364 95.9785 2.84046L36.4969 59.287C37.2562 59.9948 37.9122 60.8019 38.443 61.6878L54.3269 88.1962C58.6076 95.3403 69.7811 94.3017 72.5197 86.5052L98.4696 12.6277Z" fill="#2B3F6C"/>
              <path d="M85.6938 0.503763C89.6434 -0.745589 93.496 0.436009 96.0073 2.86774L36.5258 59.3143C35.7799 58.5937 34.9294 57.9712 33.9958 57.4675L6.06212 42.3941C-1.46603 38.3318 -0.371575 27.7284 7.84414 25.1296L85.6938 0.503763Z" fill="#2B3F6C"/>
            </g>
              <defs>
                <filter id="filter0_i_48_3040" x="1" y="0" width="98.0005" height="97" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_48_3040"/>
                </filter>
              </defs>
          </svg>
        </Link>

      <Link to="/ActiveServers" className="block active-servers">
        <span className="block-text">
          <LocaleText id="homePage.activeServers"/>
        </span>
        <span className="active-servers-count">{activeServers}</span> {/* Добавлено отображение активных серверов */}
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
            <g clipPath="url(#clip0_44_2998)" filter="url(#filter0_i_44_2998)">
              <path d="M63.435 3.22823C55.0855 -1.07607 44.9145 -1.07608 36.565 3.22822L5.60802 19.187C-1.86932 23.0417 -1.86936 32.9583 5.608 36.813L36.565 52.7718C44.9145 57.0761 55.0855 57.0761 63.435 52.7718L94.392 36.813C101.869 32.9583 101.869 23.0417 94.392 19.187L63.435 3.22823Z" fill="#23AC59"/>
              <path opacity="0.3" d="M3.7188 66C-1.80488 70.2252 -1.17517 78.331 5.608 81.6714L36.565 96.9162C44.9145 101.028 55.0855 101.028 63.435 96.9162L94.392 81.6714C101.175 78.331 101.805 70.2252 96.2812 66L67.2028 80.3197C56.5134 85.5837 43.4866 85.5837 32.7972 80.3197L3.7188 66Z" fill="#2B3F6C"/>
              <path opacity="0.3" d="M3.7188 42C-1.80488 46.4737 -1.17517 55.0563 5.608 58.5932L36.565 74.7348C44.9145 79.0884 55.0855 79.0884 63.435 74.7348L94.392 58.5932C101.175 55.0563 101.805 46.4737 96.2812 42L67.2028 57.1621C56.5134 62.7357 43.4866 62.7357 32.7972 57.162L3.7188 42Z" fill="#2B3F6C"/>
            </g>
            <defs>
              <filter id="filter0_i_44_2998" x="0" y="0" width="100" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_44_2998"/>
              </filter>
            <clipPath id="clip0_44_2998">
            <rect width="100" height="100" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </Link>

      <Link to="/Referal" className="block referral">
        <span className="block-text">
          <LocaleText id="homePage.Referal"/>
        </span>
        <span className="active-servers-count">{activeServers}</span> {/* Добавлено отображение активных серверов */}
<svg width="127" height="113" viewBox="0 0 127 113" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_44_2998)" filter="url(#filter0_di_1068_15)">
<ellipse cx="79.367" cy="26.7389" rx="22.0759" ry="21.7389" fill="#9771EC" stroke="#9771EC" stroke-width="9.5"/>
<path d="M118 75.7679C118 74.0703 116.928 72.5577 115.327 71.9944L90.3155 63.1981C83.2293 60.706 75.5049 60.706 68.4187 63.1981L43.4071 71.9945C41.8057 72.5577 40.7342 74.0703 40.7342 75.7679V97.8071C40.7342 99.0225 41.8093 99.9569 43.0128 99.7876L75.6059 95.2025C78.1011 94.8515 80.6331 94.8515 83.1283 95.2025L115.721 99.7876C116.925 99.9569 118 99.0225 118 97.8071V75.7679Z" fill="#9771EC" stroke="#9771EC" stroke-width="9.5"/>
<path d="M32.9156 53.4619H9" stroke="#9771EC" stroke-width="9.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.959 41.6829L20.959 65.2334" stroke="#9771EC" stroke-width="9.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_di_1068_15" x="0.25" y="0.25" width="126.5" height="112.309" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1068_15"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1068_15" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_1068_15"/>
</filter>
</defs>
</svg>
      </Link>
     </div>
     </div>
  );
};

export default Home;