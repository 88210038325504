// eslint-disable-next-line
import React, { useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import { svgFlags } from '../../assets/icons/svgFlags';
import { getVms } from '../../services/vms.service';
import LocaleText from '../../utils/LocaleText';
import Lottie from 'lottie-react';
import animationData from '../../assets/tgs/_016_SRCH_OUT.json';
import './ActiveServers.scss';

const ActiveServers = () => {
	const [vms, setVms] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isInitialLoad, setIsInitialLoad] = useState(true); // Добавлено состояние для отслеживания первичной загрузки

	const navigate = useNavigate();

	const fetchData = useCallback(async () => {
		if (isInitialLoad) {
			setIsLoading(true); // Начало загрузки только при первичной загрузке
		}
		try {
			const data = await getVms();
			setVms(data);
			if (isInitialLoad) {
				setIsInitialLoad(false); // Первичная загрузка завершена
			}
		} catch (error) {
			// Обработка ошибок
			if (isInitialLoad) {
				setIsInitialLoad(false); // Первичная загрузка завершена даже в случае ошибки
			}
		}
		if (isInitialLoad) {
			setIsLoading(false); // Загрузка завершена только при первичной загрузке
		}
	}, [isInitialLoad, setIsLoading, setVms]);

	useEffect(() => {
		fetchData();

		const interval = setInterval(fetchData, 10000);

		return () => clearInterval(interval);
	}, [fetchData]);

	function getServerStatusStyles(state) {
		switch (state) {
			case '1':
			case '4':
			case '5':
				return {
					background: '#FFCB12',
					boxShadow: '0px 4px 100px 0px #FFCB12',
				};
			case '2':
				return {
					background: '#4FD969',
					boxShadow: '0px 4px 100px 0px #4FD969',
				};
			case '3':
			case '6':
			case '7':
			case '8':
				return {
					background: '#FF4530',
					boxShadow: '0px 4px 100px 0px #FF4530',
				};
			default:
				return {};
		}
	}

	const animationStyle = {
		width: '200px',
		height: '200px',
	};

	const handleBackClick = () => {
		navigate('/');
	};

	const handleServerClick = (serverId) => {
		navigate('/SettingsServer', { state: { serverId } });
	};

	useEffect(() => {
		const setHeight = () => {
			const animationContainer = document.querySelector('.animation-container');
			if (animationContainer) {
				animationContainer.style.height = `${window.innerWidth}px`;
			}
		};
	
		setHeight();
	
		window.addEventListener('resize', setHeight);
	
		return () => {
			window.removeEventListener('resize', setHeight); 
		};
	}, []);

	return (
		<div>
			<BackButton onClick={handleBackClick} />
			{isInitialLoad && isLoading ? (
				// Элемент для отображения во время загрузки
				<div>
					{/* кто это смотрит, идет naхуi */}
				</div>
			) : vms.length > 0 ? (
				<div className="activeServers-container">
					{vms.length > 0 && (
						<div className="titleBlock">
							<div className="titleText">
								<LocaleText id="homePage.activeServers" />
							</div>
						</div>
					)}
					<div className="activeServerUser-container">
						{vms.map((vm) => (
							<div className="activeServerUser" onClick={() => handleServerClick(vm.id)}>
								<div className="serverInfo">
									{vm.location === 'Германия' && svgFlags[1].svg}
									{vm.location === 'Финляндия' && svgFlags[2].svg}
									<span>{vm.name}</span>
								</div>
								<div className="serverSettings">
									<div className="ActivityPeriod">
										<LocaleText id="activeServersPage.activeTo" />: <span>{vm.expires}</span>
									</div>
									<div className="AutoRenew">
										<LocaleText id="activeServersPage.autoRenewal" />: <span>{vm.autorenewal === 1 ? <LocaleText id="activeServersPage.gotIt" /> : <LocaleText id="activeServersPage.no" />}</span>
									</div>
									<div className="IP">IP: <span>{vm.ipv4}</span></div>
									<div className="MonthlyPayment">
										<LocaleText id="activeServersPage.amonth" />:
										{vm.discount > 0 ? (
											<>
												<div className="extend_discount">{(vm.price - (vm.price * vm.discount / 100)).toFixed(2).replace('.00', '')}₽</div>
												<span className="dividerPrice">/</span>
												<span className="originalPrice">{vm.price}₽</span>
											</>
										) : (
											<><span>{vm.price}₽</span></>
										)}
										<div className="serverStatus" style={getServerStatusStyles(vm.state)}>{vm.statusText}</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="animation-container">
						<Lottie
						animationData={animationData}
						loop={true} 
						autoplay={true} 
						style={animationStyle}
						/>
					<div className="titleAnimation">
						<LocaleText id="activeServersPage.noActive" />
					</div>
					<div className="subzone">
						<LocaleText id="activeServersPage.getYours" />
					</div>
				</div>
			)}
		</div>
	);
};

export default ActiveServers;
