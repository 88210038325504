// url.js

import { SET_URL_DATA } from '../actions/url';

const initialState = {  
  urlData: {}
};

const urlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_URL_DATA:
      return {
        ...state,
        urlData: action.payload  
      };

    default:
      return state;
  }
};

export default urlReducer;