// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Blocked-container .animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  animation: fadeIn 3s ease-in-out forwards;
}
.Blocked-container .animation-container .titleAnimation {
  padding-top: 5px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 700;
  color: var(--tg-theme-text-color);
}
.Blocked-container .animation-container .subzone {
  padding-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: var(--telegram-hint-color, #8E8E93);
  text-align: center;
}
.Blocked-container .animation-container .subzone span {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/Blocked/Blocked.scss"],"names":[],"mappings":"AAIC;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,UAAA;EACA,yCAAA;AAHF;AAKE;EACC,gBAAA;EACA,yCAAA;EACA,gBAAA;EACA,iCAAA;AAHH;AAME;EACC,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,0CAAA;EACA,kBAAA;AAJH;AAMG;EACC,yCAAA;EACA,gBAAA;AAJJ","sourcesContent":["\n.Blocked-container {\n\t\n\n\t.animation-container {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tflex-direction: column;\n\t\twidth: 100%;\n\t\topacity: 0;\n\t\tanimation: fadeIn 3s ease-in-out forwards;\n\t\n\t\t.titleAnimation{\n\t\t\tpadding-top: 5px;\n\t\t\tfont-family: 'SF Pro Display', sans-serif;\n\t\t\tfont-weight: 700;\n\t\t\tcolor: var(--tg-theme-text-color);\n\t\t}\n\t\n\t\t.subzone {\n\t\t\tpadding-top: 10px;\n\t\t\tmargin-left: 25px;\n\t\t\tmargin-right: 25px;\n\t\t\tfont-family: 'SF Pro Display', sans-serif;\n\t\t\tfont-weight: 400;\n\t\t\tfont-size: 13px;\n\t\t\tcolor: var(--telegram-hint-color, #8E8E93);\n\t\t\ttext-align: center;\n\n\t\t\tspan {\n\t\t\t\tfont-family: 'SF Pro Display', sans-serif;\n\t\t\t\tfont-weight: 700;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
