// selectors.js

import { createSelector } from 'reselect';

const getUser = state => state.user.user;

export const getLang = createSelector(
  [getUser],
  user => {
    if (!user) {
      return 'en';
    }

    return user.lang || 'en';
  }
);