// invoice.service.js

export function createInvoice(data) {

  const token = localStorage.getItem('token');

  return fetch('https://wafhostingapp.com/api/invoice', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}` 
    },
    body: JSON.stringify({
      payment: data.payment,
      amount: data.amount
    })
  })
  .then(res => res.json());

}