// user.js

export const SET_USER_DATA = 'SET_USER_DATA';

export const setUserData = (data) => {

  console.log('Dispatching setUserData');
  console.log('Data:', data);

  return {
    type: SET_USER_DATA,
    payload: data 
  }
};