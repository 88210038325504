// locations.service.js

export const getLocationsForCpu = async (cpu) => {

    try {
  
        const token = localStorage.getItem('token');
        
      const response = await fetch(`https://wafhostingapp.com/api/locations/${cpu}`, {
        headers: {
          Authorization: `${token}`  
        }
      });
  
      if(!response.ok) {
        return [];
      }
  
      return await response.json();
  
    } catch (error) {
      
      return [];
    
    }
  
  };