import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // Импортируем useNavigate здесь

import Home from './components/Home/Home';
import Profile from './components/Profile/Profile';
import BuyServer from './components/BuyServer/BuyServer';
import ActiveServers from './components/ActiveServers/ActiveServers';
import SettingsServer from './components/SettingsServer/SettingsServer';
import Information from './components/Information/Information';
import Blocked from './components/Blocked/Blocked';
import Referal from './components/Referal/Referal';

import { extractAllUrlData } from "./utils/decodeUrl";
import { useDispatch } from 'react-redux';
import { setUrlData } from './store/actions/url';
import AuthService from './services/AuthService';

function App() {
  const dispatch = useDispatch(); // Инициализация dispatch
  const [isAuth, setIsAuth] = useState(false)
  AuthService.isAuth = isAuth
  AuthService.setIsAuth = setIsAuth

  const [hasFetchedData, setHasFetchedData] = useState(false);

  const navigate = useNavigate(); 

  // Эффект для инициализации аутентификации
  useEffect(() => {
    const fetchData = async () => {
      if (!hasFetchedData) {
        try {
          if (!await AuthService.user()) {
            await AuthService.auth();
          }
          setHasFetchedData(true);
        } catch (e) {
          if (e.response?.status === 403) {
            const reason = e.response.data?.reason || ''; 
            navigate(`/Blocked/${reason}`);
          }
        }
      }
    };

    fetchData();
  }, [hasFetchedData, navigate]);

  // Эффект для обработки данных URL
  useEffect(() => {
    const fetchData = async () => {   
      const data = extractAllUrlData();  

      console.log('Отправляем:', data);

      if (data) {
        dispatch(setUrlData(data));  
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div>
      <Routes>
        {AuthService.isAuth ? (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/BuyServer" element={<BuyServer />} />
            <Route path="/ActiveServers" element={<ActiveServers />} />
            <Route path="/SettingsServer" element={<SettingsServer />} />
            <Route path="/Information" element={<Information />} />
            <Route path="/Referal" element={<Referal />} />
          </>
        ) : (
          <>
          <Route path="/Blocked/:reason" element={<Blocked />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
