import $api from '../http';
import { setUserData } from '../store/actions/user';
import store from '../store';

export default class AuthService {

  static async auth() {

    const Telegram = window.Telegram || {};
    const initData = Telegram.WebApp.initDataUnsafe;

    try {
      const response = await $api.post('/auth', initData);

      if (response.data?.token) {
        localStorage.setItem('token', response.data.token);
        AuthService.setIsAuth(true);
      }

    } catch (e) {
      AuthService.setIsAuth(false);
      throw e; 
    }

  }

  static async user() {

    try {
      const response = await $api.get('/user');

      const { dispatch } = store;
      
      console.log('Sending data to Redux', response.data);  

      dispatch(setUserData(response.data));

      AuthService.setIsAuth(true);
      return response.data;

    } catch(e) {
      
      AuthService.setIsAuth(false);
      return false; 
    }

  } 

}