// tarifs.service.js

export const getTarifsByCpuAndLocation = async (cpu, locationId) => {

  try {

    const token = localStorage.getItem('token');

    const response = await fetch(`https://wafhostingapp.com/api/tarifs/${cpu}/${locationId}`, {
      headers: {
        Authorization: `${token}`  
      }
    });

    if(!response.ok) {
      return [];
    }

    return await response.json();

  } catch (error) {
    
    return [];
  
  }

}