
export const getVdsScripts = (vdsId) => {

  const token = localStorage.getItem('token');

  return fetch(`https://wafhostingapp.com/api/service/vds/${vdsId}/scripts`, {
    method: 'GET',
    headers: {
      Authorization: `${token}` 
    }
  })
    .then(res => {
      if(!res.ok) {
        if(res.status === 400) {
          return res.json()
            .then(error => {
              throw new Error(error.message); 
            })
        }
        throw new Error('Error getting VDS scripts');
      }

      return res.json();
    });

}