

export const vdsActions = (action, vdsId, data) => {

  const token = localStorage.getItem('token');

  return new Promise((resolve, reject) => {

    fetch('https://wafhostingapp.com/api/service/vds/' + vdsId + '/' + action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 400) {
          return response.json()
            .then(error => {
              return reject(new Error(error.message));
            });
        }
        return reject(new Error('Error POST'));  
      }

      return response.json();
      
    })
    .then(data => {
      return resolve(data);
    })
    .catch(error => {
      return reject(error);
    });
  
  });

}