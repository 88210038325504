

// Функция для извлечения данных из хеша URL
export function extractAllUrlData() {
  try {
    // Получаем часть хеша из URL (всё, что идет после символа #)
    const hashData = window.location.hash.substr(1);

    // Используем URLSearchParams для разбора хеша на пары ключ-значение
    const params = new URLSearchParams(hashData);

    // Создаем объект для хранения извлеченных данных
    const extractedData = {};

    // Проходим по всем параметрам и добавляем их в объект extractedData
    for (const [key, value] of params.entries()) {
      // Декодируем значение (преобразуем специальные символы обратно в обычный текст)
      extractedData[key] = decodeURIComponent(value);
    }

    // Возвращаем объект с извлеченными данными
    return extractedData;
  } catch (error) {
    // В случае ошибки выводим сообщение в консоль и возвращаем null
    console.error('Ошибка при обработке данных URL:', error);
    return null;
  }
}

// Корневой компонент приложения
function App() {
}

export default App;