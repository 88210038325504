
export const upgradeTariff = (vdsId, data) => {
  const token = localStorage.getItem('token');

  return new Promise((resolve, reject) => {  
    fetch('https://wafhostingapp.com/api/service/vds/' + vdsId + '/upgradetarif', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify({id: data.tariffId}) 
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 400) {
          return response.json()
            .then(error => {
              return reject(new Error(error.message));
            });
        }
        return reject(new Error('Ошибка при обновлении тарифа'));  
      }
      return resolve();
    })
    .catch(error => {
      return reject(error); 
    });
  });
}