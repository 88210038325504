
 // eslint-disable-next-line
 import React, { useState, useEffect } from 'react';

 import { useNavigate } from 'react-router-dom';
 import { BackButton } from '@vkruglikov/react-telegram-web-app';
 import LocaleText from '../../utils/LocaleText';
 import Lottie from 'lottie-react';
 import animationTerms from '../../assets/tgs/_049_MAP_OUT.json';
 import animationFaq from '../../assets/tgs/_017_COMMENTS_OUT.json';
 import { Link } from 'react-router-dom';
 import './Referal.scss';
 
 
const Information = () => {

	/*
		Состояние для индикации скопированного  
		текста в буфер обмена
	*/
	const [copiedId, setCopiedId] = useState(null);
 
	const navigate = useNavigate();
 
	const handleBackClick = () => {
		navigate('/');
};

// const animationStyle = {
// 	width: '100px', // задайте нужную ширину
// 	height: '100px', // задайте нужную высоту
// 	position: 'absolute',
// };


const copyText = (text, type) => {
	// код для копирования в буфер    
	copyToClipboard(text);
	
	setCopiedId(type);
	setTimeout(() => setCopiedId(null), 1000);
}

const copyToClipboard = text => {
	const el = document.createElement('textarea');
	el.value = text;
	document.body.appendChild(el);
	el.select();    
	document.execCommand('copy');
	document.body.removeChild(el);
}
 
 return (
	 <div> 
		 <BackButton onClick={handleBackClick} />
			<div className="information-container">
				<div className="titleBlock">
					<div className="titleText">
						<LocaleText id="referralPage.information"/>
					</div>
				</div>
				<div className="LegalInformation-container"> 
				<Link  
					to="https://waf.group/terms/"
					target="_blank"
					style={{display: 'contents'}} 
				>
					<div className="Terms">
						<span>
							<LocaleText id="informationPage.terms"/>
						</span>
							<Lottie
								animationData={animationTerms}
								loop={true} 
								autoplay={true} 
								// style={animationStyle}
								className='animation'
							/>
					</div>
				</Link>
				<Link  
					to="https://telegra.ph/Otvety-na-chasto-zadavaemye-voprosy-01-11-4"
					target="_blank"
					style={{display: 'contents'}} 
				>
					<div className="FAQ">
						<span>
							<LocaleText id="informationPage.faq"/>
						</span>
							<Lottie
								animationData={animationFaq}
								loop={true} 
								autoplay={true} 
								// style={animationStyle}
								className='animation'
							/>
					</div>
				</Link>
				</div>
				<div className="manager">
				<LocaleText id="informationPage.manager"/>:
					<Link  
						to="https://t.me/wafsupport"
						target="_blank"
						style={{display: 'contents'}} 
					>
						<span 
							onClick={() => copyText('@wafsupport', 'wafsupport')}
							className={copiedId === 'wafsupport' ? 'copimanager' : ''}> 
							@wafsupport
						</span>
					</Link>
				</div>
			</div>
		 </div>
	 );
 };
 
 export default Information;