// user.service.js

import store from '../store';

import { setUserData } from '../store/actions/user';

export const getUser = async () => {

  try {

    const response = await fetch('https://wafhostingapp.com/api/user', {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    });

    if(!response.ok) {
      return [];  
    }

    const user = await response.json();

    // Передаем данные в Redux
    store.dispatch(setUserData(user));
    
    return user;

  } catch (error) {

    return [];
  
  }

};