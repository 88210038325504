// os.service.js 

export const getOsByCpuLocationAndTarif = async (tarif) => {

  try {

    const token = localStorage.getItem('token');

    const response = await fetch(`https://wafhostingapp.com/api/os/${tarif}`, {
      headers: {
        Authorization: `${token}`  
      }
    });

    return await response.json();

  } catch (error) {
    throw error;  
  }

}