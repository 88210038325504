// svgFlags.js

export const svgFlags = {
	"🇩🇪": {
		svg: (
		<svg xmlns="http://www.w3.org/2000/svg" width="27" height="20" viewBox="0 0 27 20" fill="none">
		<g clipPath="url(#clip0_144_6587)">
		<rect x="0.830566" width="26" height="20" rx="2" fill="#F93939"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M0.830566 13.75H28.0124V20H0.830566V13.75Z" fill="#FFDA2C"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M0.830566 0H28.0124V6.25H0.830566V0Z" fill="#151515"/>
		</g>
		<defs>
		<clipPath id="clip0_144_6587">
		<rect x="0.830566" width="26" height="20" rx="2" fill="white"/>
		</clipPath>
		</defs>
		</svg>
		),
		name: "Германия"
	},
	"🇫🇮": {
		svg: (
		<svg xmlns="http://www.w3.org/2000/svg" width="27" height="20" viewBox="0 0 27 20" fill="none">
		<g clipPath="url(#clip0_252_2314)">
		<rect x="0.982666" width="26" height="20" rx="2" fill="white"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M8.41124 12H0.982666V8H8.41124V0H12.1255V8H26.9827V12H12.1255V20H8.41124V12Z" fill="#1A47B8"/>
		</g>
		<defs>
		<clipPath id="clip0_252_2314">
		<rect x="0.982666" width="26" height="20" rx="2" fill="white"/>
		</clipPath>
		</defs>
		</svg>
		),
		name: "Финляндия"
	},
	"🇸🇪": {
		svg: (
		<svg xmlns="http://www.w3.org/2000/svg" width="27" height="20" viewBox="0 0 27 20" fill="none">
		<g clipPath="url(#clip0_254_2319)">
		<rect width="27" height="20" rx="2" fill="#3A99FF"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M7.71429 12H0V8H7.71429V0H11.5714V8H27V12H11.5714V20H7.71429V12Z" fill="#FFDA2C"/>
		</g>
		<defs>
		<clipPath id="clip0_254_2319">
		<rect width="27" height="20" rx="2" fill="white"/>
		</clipPath>
		</defs>
		</svg>
		),
		name: "Швеция"
	}
}