// Импорт React и хуков
import React, { useState, useEffect } from 'react'; 
// import React, { useState, useEffect, useRef } from 'react'; 

// Импорт хука для доступа к store Redux
import { useSelector } from 'react-redux';

// Импорт селектора языка из store 
import { getLang } from '../../store/selectors/selectors';  

// Импорт хуков маршрутизации
import { useNavigate } from 'react-router-dom';

// Импорт UI компонентов
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Импорт локальных стилей
import './Profile.scss';

// Импорт компонента Skeleton 
import Skeleton from '@mui/material/Skeleton';

// Импорт хука хаптической отзыва
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';

// Импорт компонентов маршрутизации  
import { Link } from 'react-router-dom';

// Импорт сервиса создания инвойса
import { createInvoice } from '../../services/invoice.service';

// Импорт сервиса получения данных пользователя  
import { getUser } from '../../services/user.service';

// Импорт сервиса управления языком
import { changeLanguage } from '../../services/language.service';

// Импорт сервиса промокодов
import { activatePromo } from '../../services/promocode.service';

// Импорт утилиты локализации текста  
import LocaleText from '../../utils/LocaleText';

// Основной компонент Profile
const Profile = () => {

  // Хук маршрутизации
  const navigate = useNavigate();

  // Обработчик возврата назад  
  const handleBackClick = () => {
    navigate('/');
  };

  // Состояние для хранения аватара
  const [AvatarImage, setAvatarImage] = React.useState(null);

  // Состояние для хранения баланса
  // eslint-disable-next-line  
  const [balance, setBalance] = useState(
    localStorage.getItem('balance') || 0
  );

  // Состояние для хранения активных серверов
  // eslint-disable-next-line
  const [activeServers, setActiveServers] = useState(
    localStorage.getItem('vds') || 0  
  );

  // Флаг загрузки аватара
  const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);  

  // Флаг отображения модального окна пополнения
  const [showOsModal, setShowOsModal] = useState(false);

  // Язык из Redux store
  const lang = useSelector(getLang);

  // Локальное состояние языка  
  const [selectedLang, setSelectedLang] = useState(lang);

  // Значение ввода суммы
  const [inputValue, setInputValue] = useState('10');
  
  // Состояние промокода
  const [promoCode, setPromoCode] = useState('');

  // Состояния ошибок  
  const [error, setError] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);  

  // Состояние сообщения об успехе
  const [succesMessage, setSuccesMessage] = useState(null);

  // Отображение способа оплаты
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);

  // Отображение модального окна суммы
  const [showModalAmount, setShowModalAmount] = useState(true);

  // Отображение модального окна промокода
  const [showModalPromo, setShowModalPromo] = useState(false);

  // Флаг успешного применения промокода
  const [showSuccess, setShowSuccess] = useState(false);
  
  // Флаг общего успеха  
  const [success, setSuccess] = useState(false);

  // Выбранная сумма пополнения
  const [amount, setAmount] = useState(null);

  // Данные загруженного пользователя
  const [user, setUser] = useState(null);  

  // Подключение хаптической отзыва  
  const [, notificationOccurred] = useHapticFeedback();

  if(error) {
    notificationOccurred('error');
  }

  // Загрузка данных пользователя
  useEffect(() => {
    getUser()
      .then(data => {
        setUser(data);
      }) 
  }, []);

  // Обработчик изменения ввода промо-кода
  const handlePromoCodeChange = (event) => {
    const newCode = event.target.value
      .replace(/[^a-zA-Zа-яА-Я0-9-]/g, '') 
      .slice(0, 10);
  
    setPromoCode(newCode); 
  }
  
  // Обработчик изменения значения в поле ввода пополнения баланса
  const handleChange = e => {

    const newValue = e.target.value;
  
    if (newValue.length > 9) {
      return; 
    }
  
    if (newValue === "") {
      setInputValue("0");
      return;
    }
  
    if (newValue === "0") {
      setInputValue("0");
      return;  
    }
  
    // Форматирование и фильтрация строки
    let formatted = newValue;
    
    if (newValue !== "0") {
  
      formatted = newValue
        .replace(/^0+/, '')
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  
      if (formatted === "") {
        formatted = "0"; 
      }
  
    }
  
    setInputValue(formatted);
  }
  
  // Обработчик клика: смена языка и получение информации о пользователе
  const handleClick = async () => {
    
    await changeLanguage(selectedLang === 'ru' ? 'en' : 'ru');
  
    setSelectedLang(selectedLang === 'ru' ? 'en' : 'ru');

    // eslint-disable-next-line
    const user = await getUser();
  }

  // Загрузка аватара пользователя при монтировании компонента
  useEffect(() => {
    fetch('/api/avatar', {
      headers: {
      Authorization: localStorage.getItem('token')
      }
    })

    .then(response => {
      const contentType = response.headers.get('Content-Type');

      if (contentType && contentType.includes('image/')) {
      return response.blob();
      } else {
      return null;
      }
    })
    
    // Обработка Blob с изображением
    .then(imageBlob => {
      if (imageBlob) {
      setIsAvatarLoaded(true);
      setAvatarImage(URL.createObjectURL(imageBlob));
      } 
      
      else {
        setIsAvatarLoaded(true);
      }
    });

  }, []);

  // Компонент-заглушка для аватара пользователя
  const AvatarPlaceholder = () => (
    <div className="avatar-placeholder">
      <div className="user-name-placeholder">
      {user?.username.slice(0, 2) || ''}
      </div>
    </div>  
  );
  
  // Обработчик клика для пополнения баланса
  const handleReplenishClick = () => {
    setShowModalPromo(false);
    setShowOsModal(true);
    setShowModalAmount(true); 
  };

  const handleModalAmountClick = () => {
    setShowOsModal(false);
  };

  const handleBackPaymentMethodClick = () => {
    setShowModalAmount(true);
    setShowPaymentMethod(false);
  };
  
  // Обработчик клика по кнопке "Далее" в модальном окне ввода суммы
  const handleNextClick = () => {

    const amountString = inputValue.replace(/\s/g, "");  
    const amount = +amountString;
  
    if (amount < 10) {
      setErrorMessage(<LocaleText id="profilePage.minAmount"/>); 
      setError(true);
      return;
    }
  
    if (amount > 1000000) {
      setErrorMessage(<LocaleText id="profilePage.maxAmount"/>); 
      setError(true);
      return; 
    }

    setShowModalAmount(false); 
    setShowPaymentMethod(true);

    setAmount(amount); 
  }

  // Эффект, обрабатывающий иуправления временным отображением сообщения об ошибке
  useEffect(() => {

    if (error) {
  
      const timeout = setTimeout(() => {
        setError(false);
        setErrorMessage(null);  
      }, 1000);
  
      return () => clearTimeout(timeout);
    
    }
  
  }, [error]);


  let canClick = true;
  
  // Обработчик клика по способу оплаты
  function handlePaymentMethodClick(payment) {

    if(!canClick) return;
  
    canClick = false;
  
    createInvoice({payment, amount})
      .then(response => {
  
        if(response.url) {
  
          setTimeout(() => {
            window.open(response.url, '_blank', 'noopener');
          }, 100);
  
        }
  
        setTimeout(() => {
          canClick = true;  
        }, 10000);
  
      });

      setShowModalAmount(false); 
      setShowPaymentMethod(false);
      setShowOsModal(false);
  }

  const handlePromoClick = () => {
    setShowOsModal(true);
    setShowModalPromo(true);  
    setShowModalAmount(false); 
  };

  const handleModalPromoClick = () => {
    setShowSuccess(false);
    setShowOsModal(false);
    setShowSuccess(false);
    setSuccess(false);
  };

  // Обработчик клика по кнопке "Apply" для применения промокода
  const handleApplyClick = async () => {

    if(!promoCode) {
      setError(true);
      setErrorMessage(<LocaleText id="profilePage.enterPromo"/>);
      return; 
    }
  
    try {
      const response = await activatePromo(promoCode);
      setSuccesMessage(response.message); 
      setShowSuccess(true);
      setSuccess(true);
      
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);  
    }
  }

  return (
    <div>
      <BackButton onClick={handleBackClick} />
      {showOsModal && 
        <div className="modalAmount-container" >
        {showModalPromo && (
          <div className="modalPromo">
            <div className="modalPromo-title">
            <span> 
                <LocaleText id="profilePage.promotTitle"/>
              </span> 
              <div className="modalPromo-backButton" onClick={handleModalPromoClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                  <g clipPath="url(#clip0_655_6470)">
                    <path d="M1.78089 1.76499L11.311 11.3268M11.3268 1.78085L1.76504 11.3109" stroke="#8E8E93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_655_6470">
                      <rect width="13" height="13" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            {showSuccess && 
              <div className={`success-animation ${success ? 'success' : ''}`}>
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
            </div>
            }
            {!showSuccess && 
            <div className="modalPromo-input">
              <input
                style={{ WebkitTapHighlightColor: 'transparent' }}
                spellCheck={false}
                type="text"
                inputMode="text"
                value={promoCode}
                onChange={handlePromoCodeChange}
                placeholder="WaF"
              />
              <div className="promo-input">
                <span>{promoCode}</span>
              </div>
            </div> 
            }
            <div 
              className={`applyButton ${error ? 'error' : ''} ${success ? 'success' : ''}`}
              onClick={handleApplyClick}
            >

              <span>
                {errorMessage ? errorMessage : <LocaleText id="profilePage.apply" />}  
              </span>

            </div>
            {showSuccess && 
            <div className="succes-text">
              <span>
                {succesMessage}
              </span>
            </div>
            }
          </div>
        )}
        {showModalAmount && 
          <div className="modalAmount">

            <div className="modalAmount-title">
              <span> 
                <LocaleText id="profilePage.amountTitle"/>
              </span> 
              <div className="modalAmount-backButton" onClick={handleModalAmountClick}>

                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                  <g clipPath="url(#clip0_655_6470)">
                    <path d="M1.78089 1.76499L11.311 11.3268M11.3268 1.78085L1.76504 11.3109" stroke="#8E8E93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_655_6470">
                      <rect width="13" height="13" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

              </div>
            </div>
            <div className="modalAmount-input">
              <input
                style={{ WebkitTapHighlightColor: 'transparent' }}
                type="text"
                inputmode="decimal"
                value={inputValue}
                onChange={handleChange}
              />
              <div className="amount-input">
                <span className={error ? 'shake' : ''}>{inputValue}</span>  
              </div>
              <span>₽</span>  
            </div>
            <div 
              className={`nextButton ${error ? 'error' : ''}`}
              onClick={handleNextClick}  
            >
              <span>
                 {errorMessage ? errorMessage : <LocaleText id="profilePage.next" />}  
              </span>
            </div>
            
          </div>
          }
          {showPaymentMethod && (
            <div className="payment-method-container">

              <div className="payment-method-title">
                <span>
                  <LocaleText id="profilePage.methodTitle"/>
                </span>

                <div className="payment-method-backButton" onClick={handleBackPaymentMethodClick}>

                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <g clipPath="url(#clip0_655_6470)">
                      <path d="M1.78089 1.76499L11.311 11.3268M11.3268 1.78085L1.76504 11.3109" stroke="#8E8E93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_655_6470">
                        <rect width="13" height="13" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>

                </div>
              </div>
              <div className="payment-methods">
              <div className="payment-method" onClick={() => handlePaymentMethodClick('lolz')}>

                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">

                    <g mask="url(#mask0_625_6252)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.48071 16.2024C7.17323 17.5774 9.12849 18.361 11.2137 18.361C14.3147 18.361 17.1297 16.623 19.2186 13.7923C18.3366 12.5973 17.3255 11.5965 16.217 10.8398L14.4372 11.7284C14.8197 12.3234 15.0426 13.0319 15.0426 13.7923C15.0426 15.9044 13.3287 17.6164 11.2137 17.6164C9.57045 17.6164 8.16943 16.5821 7.6263 15.1301L5.48071 16.2015V16.2024ZM9.89631 13.9974C9.93896 14.275 10.069 14.5331 10.2704 14.7346C10.5202 14.9843 10.86 15.1245 11.2137 15.1236C11.95 15.1236 12.5469 14.5275 12.5469 13.7923C12.5469 13.4311 12.403 13.1034 12.169 12.8629L9.89724 13.9974H9.89631Z" fill="#2BAD72"/>
                      <path d="M5.96515 13.7624L18.938 7.52622L16.0868 1.59546L14.6143 6.8364L12.8448 3.15428L11.3713 8.39523L9.60181 4.7131L8.12837 9.95406L6.35881 6.27192L4.8854 11.5129L3.11583 7.83078L0.73999 16.2748L3.98297 14.7159L5.96515 13.7634V13.7624Z" fill="#2BAD72"/>
                    </g>
                  </svg>

                  <span>Lolz</span>
                </div>
                <div className="payment-method" onClick={() => handlePaymentMethodClick('cryptomus')}>

                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                    <path d="M17.4219 4.58698L9.57405 0.150257C9.22044 -0.0500855 8.77951 -0.0500855 8.42394 0.150257L0.576039 4.58698C0.220469 4.78734 0 5.16082 0 5.56343V14.4369C0 14.8376 0.220469 15.2111 0.576039 15.4133L8.42394 19.8502C8.59875 19.9494 8.79737 20 8.99997 20C9.20257 20 9.4012 19.9475 9.57601 19.8502L17.424 15.4133C17.7795 15.213 18 14.8396 18 14.4369V5.56343C18 5.16274 17.7795 4.78927 17.424 4.58698H17.4219ZM9.17675 9.44971C9.06752 9.51196 8.93046 9.51196 8.82123 9.44971L1.19179 5.13746L8.82123 0.825205C8.9285 0.764908 9.06949 0.764908 9.17675 0.825205L16.8062 5.13746L9.17675 9.44971ZM8.42394 10.1227C8.47956 10.1538 8.53915 10.1811 8.60273 10.2025V19.0487L0.973285 14.7384C0.864058 14.6762 0.794545 14.5615 0.794545 14.4369V5.81047L8.42394 10.1227Z" fill="var(--tg-theme-text-color)"/>
                  </svg>

                  <span>Cryptomus</span>
                </div>
                <div className="payment-method" onClick={() => handlePaymentMethodClick('crystalpay')}>

                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path d="M13.2223 1L16.5557 7.66667L11.0001 21" stroke="#19ACFF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.77767 1L5.44434 7.66667L10.9999 21" stroke="#19ACFF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.5556 1H5.44444L1 7.66667L11 21L21 7.66667L16.5556 1Z" stroke="#19ACFF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 7.66675H21" stroke="#19ACFF" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <span>Crystalpay</span>
                </div>
                <div className="payment-method" onClick={() => handlePaymentMethodClick('enotio')}>

                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                    <path d="M5.60786 20V13.5697H0L7.50803 0V6.45477H13L5.60786 20Z" fill="#FFDF54"/>
                  </svg>

                  <span>Enotio</span>
                </div>
              </div>
            </div> 
          )}
        </div>  
      }
    <div className={`blur ${showOsModal ? 'active' : ''}`}>
      <div className="profile-container">

        <div className="avatar">
          {isAvatarLoaded ? (
          AvatarImage ? (
            <img src={AvatarImage} alt="Avatar" />  
          ) : (
            <AvatarPlaceholder />
          )
          ) : (
            <Skeleton 
              animation="wave"
              variant="circular"  
              width={80}
              height={80}
            />
          )}
        </div>

        <div className="UserInfo">
          <div className="UserName">
          <LocaleText id="profilePage.name"/>:
          {user && (
            <span> {user?.username || ''}</span> 
          )}
          </div>
          <div className="UserId"> 
            ID:         
            {user && (
            <span> {user.tg_id}</span>
            )}
          </div>
        </div>

      </div>

      <div className="UserSettings">

        <div className="Balance">
          <span className="block-text">
            <LocaleText id="profilePage.balance"/>
          </span>
          <span className="profile-balance">{balance}₽</span> {/* Добавлено отображение баланса */}
        </div>

        <Link to="/ActiveServers" className="ActiveServers">
          <span className="block-text">
            <LocaleText id="profilePage.activeServers"/>
          </span>
          <span className="active-servers-count">{activeServers}</span> {/* Добавлено отображение активных серверов */}
        </Link>
        
        <div className="Replenish" onClick={handleReplenishClick}>
          <span className="block-text">
            <LocaleText id="profilePage.topupBalance"/>
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <g filter="url(#filter0_i_554_4776)">
            <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M25.641 19.6763C11.9518 19.6763 0.854492 30.9144 0.854492 44.7773V74.8986C0.854492 88.7615 11.9518 99.9996 25.641 99.9996H75.214C88.4876 99.9996 99.3243 89.4335 99.9701 76.1536H77.6927C68.7947 76.1536 61.5814 68.8488 61.5814 59.8379C61.5814 50.827 68.7947 43.5223 77.6927 43.5223H99.9701C99.3243 30.2424 88.4876 19.6763 75.214 19.6763H25.641ZM20.6837 73.6435C18.6303 73.6435 16.9657 75.3292 16.9657 77.4087C16.9657 79.4881 18.6303 81.1738 20.6837 81.1738H50.4275C52.4809 81.1738 54.1455 79.4881 54.1455 77.4087C54.1455 75.3292 52.4809 73.6435 50.4275 73.6435H20.6837Z" fill="#2B3F6C"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M77.6926 51.0527H100V68.6235H77.6926C72.9014 68.6235 69.0173 64.6901 69.0173 59.8381C69.0173 54.9861 72.9014 51.0527 77.6926 51.0527ZM77.6926 56.0729C75.6392 56.0729 73.9746 57.7587 73.9746 59.8381C73.9746 61.9175 75.6392 63.6033 77.6926 63.6033H78.6841C80.7375 63.6033 82.402 61.9175 82.402 59.8381C82.402 57.7587 80.7375 56.0729 78.6841 56.0729H77.6926Z" fill="#2B3F6C"/>
            <path d="M87.5467 14.6211C83.9721 4.62403 73.5313 -1.83018 62.53 0.463536L19.8937 9.35299C10.9885 11.2097 3.77368 17.1547 0 25.0131C5.88661 17.1929 15.1822 12.1461 25.6417 12.1461H75.2147C79.5829 12.1461 83.748 13.0263 87.5467 14.6211Z" fill="#2B3F6C"/>
          </g>
          <defs>
            <filter id="filter0_i_554_4776" x="0" y="0" width="100" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_554_4776"/>
            </filter>
          </defs>
        </svg>
        </div>

        <div className="Promocode" onClick={handlePromoClick}>
          <span className="block-text">
            <LocaleText id="profilePage.promocode"/>
          </span>
        </div>
        
        <div className="LangSelect" onClick={handleClick}>
        {selectedLang === 'ru' ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
              <g clipPath="url(#clip0_574_5668)">
                <rect width="22" height="16" rx="2" fill="#1A47B8"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 10.6667H22V16.0001H0V10.6667Z" fill="#F93939"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0H22V5.33333H0V0Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_574_5668">
                  <rect width="22" height="16" rx="2" fill="white"/>
                </clipPath>
              </defs>
            </svg>
        ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
              <g clipPath="url(#clip0_574_5621)">
                <rect width="22" height="16" rx="2" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7 0H22V1.06667H7V0ZM7 2.13333H22V3.2H7V2.13333ZM7 4.26667H22V5.33333H7V4.26667ZM7 6.4H22V7.46667H7V6.4ZM0 8.53333H22V9.6H0V8.53333ZM0 10.6667H22V11.7333H0V10.6667ZM0 12.8H22V13.8667H0V12.8Z" fill="#F93939"/>
                <path d="M22 14.9333H0V16H22V14.9333Z" fill="#F93939"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0H7V7.5H0V0Z" fill="#1A47B8"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M3.5 5.14547L1.95492 6L2.25 4.18966L1 2.91056L2.72746 2.64655L3.5 1L4.27254 2.64547L6 2.90948L4.75 4.19181L5.04508 6" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_574_5621">
                  <rect width="22" height="16" rx="2" fill="white"/>
                </clipPath>
              </defs>
            </svg>
        )}

        <span className="block-text">
          {selectedLang === 'ru' ? 'Русский' : 'English'}  
        </span>
      </div>

      </div>
    </div>
    </div>
  );
};

export default Profile;