// user.service.js

export const getBalance = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await fetch(`https://wafhostingapp.com/api/balance`, {
      headers: {
        Authorization: token
      }
    });

    if(!response.ok) {
      return [];
    }

    return await response.json();
  } catch (error) {
    return [];
  }
}