
export async function getVms() {
  const token = localStorage.getItem('token');

  const response = await fetch('https://wafhostingapp.com/api/services/vds', {
    headers: {
      Authorization: `${token}`
    }
  });

  return await response.json();
}

export async function getVmById(id) {

  const token = localStorage.getItem('token');
  
  try {
    const response = await fetch(`/api/service/vds/${id}`, {
      headers: {
        Authorization: `${token}`
      }
    });
    
    if (response.status === 404) {
      throw new Error('VM not found');  
    }
    
    return await response.json();
    
  } catch (error) {
    console.error(error);
    // Обработка ошибки
    return null; 
  }

}