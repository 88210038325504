import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LocaleText from '../../utils/LocaleText';
import Lottie from 'lottie-react';
import animationData from '../../assets/tgs/_051_GHSTBST_OUT.json';
import './Blocked.scss';

const Blocked = () => {
  // Получаем параметры маршрута с помощью useParams
  const { reason } = useParams();

	const animationStyle = {
    width: '200px', // задайте нужную ширину
    height: '200px', // задайте нужную высоту
  };

	useEffect(() => {
    const animationContainer = document.querySelector('.animation-container');

    const setHeight = () => {
      animationContainer.style.height = `${window.innerWidth}px`;
    }
    
    setHeight();
    
    window.addEventListener('resize', setHeight);
    
    return () => {
      window.removeEventListener('resize', setHeight); 
    }
  }, []);

  return (
    <div>
      <div className="Blocked-container">
				<div className="animation-container">
					<Lottie
						animationData={animationData}
						loop={true} 
						autoplay={true} 
						style={animationStyle}
					/>
					<div className="titleAnimation">
						<LocaleText id="blockedPage.youBlocked"/>
					</div>
					<div className="subzone">
						<LocaleText id="blockedPage.reason"/>: <span>{reason}</span>
					</div>
				</div>
			</div>
    </div>
  );
};

export default Blocked;
