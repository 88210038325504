// url.js (action creator)

export const SET_URL_DATA = "SET_URL_DATA";

export const setUrlData = (data) => {

  console.log('Dispatching URL data:', data);

  return {
    type: SET_URL_DATA,
    payload: data  
  }
};