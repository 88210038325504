// Импорт хуков и компонентов React 
import React, { useState, useEffect, useRef, useCallback } from 'react';

// Хуки маршрутизации
import { useNavigate } from 'react-router-dom';  
import { useLocation } from 'react-router-dom';

// Компоненты UI библиотеки 
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Импорт сервисов 
import { getVmById } from '../../services/vms.service';  
import { vdsActions } from '../../services/actions.service';
import { getVdsScripts } from '../../services/scripts.service';
import { getTarifs } from '../../services/tarif.service';
import { upgradeTariff } from '../../services/upgradetarif.service';

// Redux  
import { useSelector } from 'react-redux';    

// Стили компонента
import './SettingsServer.scss';

// Сервисы ОС
import { getOsByCpuLocationAndTarif } from '../../services/os.service';  

// Локализация  
import LocaleText from '../../utils/LocaleText';  

// Дополнительные компоненты
import { useShowPopup } from '@vkruglikov/react-telegram-web-app';
import { svgOSicons } from '../../assets/icons/svgOSicons';

// UI компонент кнопки
import { MainButton } from '@vkruglikov/react-telegram-web-app';  

// Селектор языка из Redux  
import { getLang } from '../../store/selectors/selectors';   

// Хук хаптической отзыва
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';

const SettingsServer = () => {
	// Хук маршрутизации
	const navigate = useNavigate();
	const location = useLocation();

	// Хук для отслеживания изменений состояния
	const [vm, setVm] = useState(null);

	// Хук для хранения активной вкладки 
	const [activeTab, setActiveTab] = useState('information');  

	// Ссылки на блоки информации и инструкций для анимации
	const infoRef = useRef(null);
	const instructionRef = useRef(null);  

	// Ссылка на элемент подсветки вкладок
	const highlightRef = useRef(null);   

	/*
		Состояние для хранения смещения  
		подсвеченного элемента по оси X  
	*/
	const [translateX, setTranslateX] = useState(0);

  // Флаг актива дял toggle-container
	// eslint-disable-next-line 
	const [active, setActive] = useState(false);

	// Флаг актива для price tarif
	const [activeBg, setActiveBg] = useState({});

	// Флаг выбранного id tarif
	// eslint-disable-next-line 
	const [selectedId, setSelectedId] = useState(null);

	// Флаг для однократного вызова обработчика   
	const didCall = useRef(false);

	/*
		Состояние для индикации скопированного  
		текста в буфер обмена
	*/
	const [copiedId, setCopiedId] = useState(null);

	// Открытие модальных окон
	const [showOsModal, setShowOsModal] = useState(false);  
	const [showOsModalversion, setShowOsModalversion] = useState(false);
	const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
	const [isIPModalOpen, setIsIPModalOpen] = useState(false);
	const [isReinstallModalVisible, setIsReinstallModalVisible] = useState(false);
	const [isScriptsModalOpen, setIsScriptsModalOpen] = useState(false);
	const [isTarifModalOpen, setIsTarifModalOpen] = useState(false); 

	// Обработка ошибок
	const [errorMessage, setErrorMessage] = useState(null);  
	const [error, setError] = useState(null);

	// Управление скриптом
	const [scripts, setScripts] = useState([]);

	// Управление скриптом
	const [tarifs, setTarifs] = useState([]);

	// Управление паролем
	const [password, setPassword] = useState(null);  
	const [newPassword, setNewPassword] = useState('');

	// Список доступных ОС 
	const [osList, setOsList] = useState([]);

	// Выбранные версии ОС
	const [selectedVersions, setSelectedVersions] = useState({});   

	// Выбранная ОС и версия
	const [selectedOs, setSelectedOs] = useState(null);   
	const [selectedVersion, setSelectedVersion] = useState(null);

	// Отображение кнопки
	const [showIpButton, setShowIpButton] = useState(false);  
	const [showScriptButton, setShowScriptButton] = useState(false);  
	const [showTarifButton, setShowTarifButton] = useState(false);  

	// Выбранный скрипт
	const [selectedScriptId, setSelectedScriptId] = useState(null);

	// Счетчик IP адресов
	const [count, setCount] = useState(1);  

	// Язык из Redux
	const lang = useSelector(getLang);

	// Обработка тактильной отзывчивости
	const [, notificationOccurred] = useHapticFeedback();

	const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);

  
  if(error) {
    notificationOccurred('error');
  }

  useEffect(() => {
    if(showOsModal) {
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.webkitOverflowScrolling = 'touch';
    } else {
      document.documentElement.style.overflow = 'auto'; 
      document.documentElement.style.webkitOverflowScrolling = 'auto';
    }
  }, [showOsModal])

  const copyText = (text, type) => {
    // код для копирования в буфер    
		copyToClipboard(text);
    
    setCopiedId(type);
    setTimeout(() => setCopiedId(null), 1000);
  }
  
  const copyToClipboard = text => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();    
    document.execCommand('copy');
    document.body.removeChild(el);
  }


  const handleBackClick = () => {
    navigate('/ActiveServers');
  };

  useEffect(() => {
    if (location.state?.serverId) {

      getVmById(location.state.serverId)
        .then(server => {
          setVm(server);
        })
        .catch(error => {
          if (error.message === 'VM not found') {
            navigate('/active-servers');
          } else {
            console.error(error);
          }
        });

    }

    const interval = setInterval(() => {
      if (location.state?.serverId) {

        getVmById(location.state.serverId)
          .then(server => {
            setVm(server);  
          })
          .catch(error => {
            // обработка ошибки
          });

      }
    }, 10000);

    return () => clearInterval(interval);

	}, [location.state, navigate])

	function getServerStatusStyles(state) {

		switch(state) {
	
			case '1':
			case '4': 
			case '5':
				return {
					background: '#FFCB12',
					boxShadow: '0px 4px 100px 0px #FFCB12',  
				};
	
			case '2':
				return {
					background: '#4FD969',
					boxShadow: '0px 4px 100px 0px #4FD969',
				};
			
			case '3':
			case '6':
			case '7': 
			case '8':
				return {
				 background: '#FF4530',
				 boxShadow: '0px 4px 100px 0px #FF4530',
				};
	
			default:
				return {};
		}
	}

	const stateToDisabledButtonsMap = {
		3: [
			'stopButton',
			'changePasswordButton',
			'runButton'
		],
		4: [
			'changePasswordButton',
			'runButton'
		],
		5: [
			'changePasswordButton', 
			'runButton',
			'startButton',
			'stopButton',
			'rebootButton',
			'upgradeTarifButton',
			'reinstalButton'
		],
		8: [
			'changePasswordButton',
			'runButton',
			'startButton',
			'stopButton',  
			'rebootButton',
			'ipButton',
			'reinstalButton',
			'upgradeTarifButton'
		]
	};
	
	function getButtonStyles(state, id) {
		if (stateToDisabledButtonsMap[state]?.includes(id)) {
			return {
				pointerEvents: 'none',
				color: 'var(--telegram-hint-color, #8E8E93)'
			};
		}
	
		return {};
	}

  function showMainIpButton() {
    setShowIpButton(true);
  }  

	function showMainScriptButton() {
    setShowScriptButton(true);
  }  
	
	function showMainTarifptButton() {
    setShowTarifButton(true);
  } 

	const handleInfoClick = () => {
		setActiveTab('information');
		setTranslateX(0);
		console.log(1);
		updateHighlight(); 
	}
	
	const handleInstructionClick = () => {
		setActiveTab('instruction'); 
		setTranslateX(-50);
		console.log(2);
		updateHighlight();
	}

	const updateHighlight = useCallback(() => {
		const activeEl = activeTab === 'information' ? infoRef.current : instructionRef.current;
		if (activeEl && highlightRef.current) {
			highlightRef.current.style.width = `${activeEl.offsetWidth}px`;
			highlightRef.current.style.left = `${activeEl.offsetLeft}px`; 
		}
	}, [activeTab, infoRef, instructionRef, highlightRef]);


	useEffect(() => {
		if (!didCall.current) {
			handleInfoClick();
			didCall.current = true;
		} 
	})

	useEffect(() => {
		const timeout = setTimeout(() => {
		updateHighlight();
		console.log("updateHighlight");
		}, 270);
		return () => clearTimeout(timeout);
	}, [updateHighlight]);

	const showPopup = useShowPopup();

	const handleButtonClick = (action) => {

		const isRussian = lang === 'ru';
	
		let title = isRussian ? 'Подтвердите действие' : 'Confirm action';
	
		if (action === 'extend') {
	
			// если действие "extend", то спрашиваем подтверждение
			showPopup({
				title: title,
				message: isRussian ? 'Вы уверены, что хотите продлить сервер?' : 'Are you sure you want to extend the server?', 
				buttons: [
					{
						id: 'confirm_extend',
						type: 'ok',
						title: isRussian ? 'Да' : 'Yes' 
					},
					{
						id: 'cancel_extend',
						type: 'cancel', 
						title: isRussian ? 'Нет' : 'No'
					}
				]
			}).then(buttonId => {
	
				console.log('User clicked:', buttonId);
	
				if (buttonId === 'confirm_extend') {
	
					// продолжаем, если подтвердил
					executeRequest(action);
	
				} else {
					
					return; // выходим, если отменил
	
				}
	
			});
	
		} else {
	
			executeRequest(action);
	
		}
	
	};
	
	function executeRequest(action) {
		const isRussian = lang === 'ru';

		return vdsActions(action, location.state.serverId)
			.then(response => {
	
				let title = isRussian ? 'Успешно' : 'Success';
	
				const successWord = isRussian ? 'успешно' : 'successfully';
	
				if (!response.message.includes(successWord)) {
	
					title = isRussian ? 'Ошибка' : 'Error';
	
				}
	
				showPopup({
					title: title, 
					message: response.message  
				}).then(() => {});
	
			})
			.catch(error => {
			
				console.log(error);
			
			})
			.finally(() => {
	
				getVmById(location.state.serverId)
					.then(server => {						
						setVm(server);
					})
					.catch(error => {
						console.log(error);
					});
			});
	}

	function handleAddIpChangeClick() {
		setShowOsModal(true)
		setIsPasswordModalOpen(false);
		setShowOsModalversion(false);
		setIsIPModalOpen(true);
		setIsReinstallModalVisible(false);
	}

	function handlePasswordChangeClick() {
		setShowOsModal(true)
		setIsPasswordModalOpen(true);  
		setIsReinstallModalVisible(false);
	}

	useEffect(() => {

    if (error) {
  
      const timeout = setTimeout(() => {
        setError(false);
        setErrorMessage(null);  
      }, 1000);
  
      return () => clearTimeout(timeout);
    
    }
  
  }, [error]);

	// eslint-disable-next-line
	const regex = /^[A-Za-z0-9\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\}\\\|\;\:\"\'\<\>\,\.\?\/]*$/;


	function handlePasswordChange(e) {
		const maxLength = 16;
		
		const value = e.target.value;
		
		if (value.length > maxLength) {
			// обрезаем до 16 символов
			const trimmedValue = value.slice(0, maxLength); 
			
			if (regex.test(trimmedValue)) {  
				setPassword(trimmedValue);
			} else {
				setPassword('');
			}
	
		} else if (regex.test(value)) {
			setPassword(value);
	
		} else {
			setPassword('');
		}
	}

	const handleModalIPClick = () => { 
		setShowOsModal(false)
		setIsPasswordModalOpen(false);
		setShowOsModalversion(false);
		setIsIPModalOpen(false);
	}

	const handleModalSettingsClick = () => { 
		setShowOsModal(false)
		setIsPasswordModalOpen(false);
		setShowOsModalversion(false);
		setIsIPModalOpen(false);
		setIsScriptsModalOpen(false);
		setIsTarifModalOpen(false);
		setShowScriptButton(false);
		setShowTarifButton(false);
		setActiveBg({});

		console.log(123123123);

	}

	const handleApplyIPClick = () => {

		const vdsId = location.state.serverId;
		vdsActions('buyip', vdsId, {count})
			.then(result => {
				console.log('Result:', result); 
				setShowOsModal(false)
				setIsPasswordModalOpen(false);
				setShowOsModalversion(false);
				setIsIPModalOpen(false);
			})
			.catch(error => {
				setErrorMessage(error.message); 
				setError(true);
			});
	}

	const handleModalPasswordClick = () => {
    setShowOsModal(false);
  };

	const handleApplyClick = async () => {
		console.log(1);

		if(!password) {
      setError(true);
      setErrorMessage(<LocaleText id="settingsServer.enterPassword"/>);
      return; 
    }

		try {
			await vdsActions('changepassword', location.state.serverId, {
				password
			});
			
				setShowOsModal(false)
				setIsPasswordModalOpen(false);  
			} catch(error) {
			// обработка ошибки
		}
  }

	async function handleReinstallOs() {

		try {

      const response = await getOsByCpuLocationAndTarif(vm.tarif_id);

      // Получаем типы ОС 
      const osTypes = Object.keys(response);

      // Формируем объекты с типом и версиями
      const osList = osTypes.map(type => {
      
        return {
          type: type,
          items: response[type]  
        }

      });
        
      // Запись в state
      setOsList(osList);

      console.log(osList);

    } catch (error) {

      console.error('Ошибка:', error);
    
    }

		setShowOsModal(true)
		setIsPasswordModalOpen(false); 
		setIsTarifModalOpen(false);
		setIsReinstallModalVisible(true); 
	}


	// Функция для нажатия на OS
	function handleOsClick(os) {

		setSelectedOs(os);
			
		setShowOsModalversion(true);

		// Состояние для хранения информации OS
		const {type, items} = os;

		console.log('Выбрана OS:', type); 
		console.log('Версия:', items[0].name);		
	}

	// Функция которая задает выбор первых версий ОС по умолчанию
	useEffect(() => {
		const defaultVersions = {};
		
		osList.forEach(os => {
			defaultVersions[os.type] = os.items[0];  
		});
		
		setSelectedVersions(defaultVersions);
	}, [osList]);
		 
	// Функция которая обрабатывает выбор версии операционной системы в интерфейсе 
	function handleVersionSelect(version) {

		setSelectedVersions(prev => ({
			...prev,
			[selectedOs.type]: version  
		}));
		
		showMainIpButton();
	}
		
	// Функция которая следит за выбранной версией операционной системы 
	useEffect(() => {
	
		const selectedVersion = selectedVersions[selectedOs?.type];
	
		if(selectedVersion) {
			setSelectedVersion(selectedVersion); 
		}
	
	}, [selectedOs, selectedVersions]);

	const osStyle = {
		outline: '1px solid #8E8E93'
	};

	function handleGeneratePassword() {

		const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		
		let password = "";
		
		const length = 16; 
	
		for (let i = 0; i < length; i++) {
	
			const randomIndex = Math.floor(Math.random() * charset.length);
			
			if(Math.random() > 0.5) {
				password += charset[randomIndex].toUpperCase(); 
			} else {
				password += charset[randomIndex];
			}
	
		}
		
		password = password.split('').sort(function(){return 0.5-Math.random()}).join('');
	
		setNewPassword(password);

	}

	function handleChange(event) {
		const input = event.target.value;
		const cleanedInput = input.replace(/[а-яА-Я]/g, '');
		
		setNewPassword(cleanedInput);
	}
	
	const decrement = () => {
		if (count > 1) {
			setCount(count - 1);
		}
	}

	const increment = () => {
		if (count < 8) {
			setCount(count + 1);  
		}
	}	

	const handleButtonIpClick = () => {
		const vdsId = location.state.serverId;

    // Проверка на пустой пароль
    if (!newPassword) {
			setIsPasswordEmpty(true); // Устанавливаем состояние для красной обводки

			// Убираем обводку через 2 секунды
			setTimeout(() => {
					setIsPasswordEmpty(false);
			}, 2000);

			return; // Выходим из функции, если пароль пуст
		}

		console.log(selectedVersion.id);
	
		if (newPassword) {
			vdsActions('reinstall', vdsId, {
				os: selectedVersion.id,
				password: newPassword  
			})
			.then(result => {
				getVmById(vdsId)
				setShowOsModal(false);
				setIsPasswordModalOpen(false);
				setIsReinstallModalVisible(false);
				setShowIpButton(false);
			})
			.catch(error => {
			
			});
		}
	}

	const handleRunButtonClick = () => {
		const isRussian = lang === 'ru';

		getVdsScripts(location.state.serverId)
			.then(response => {
				setScripts(response);   

			})
			.catch(error => {

				const title = isRussian ? 'Ошибка' : 'Error';
			
				showPopup({
					title,
					message: error.message
				}).then(buttonId => {
					console.log(buttonId)
				});
			
			});
		
		
		setShowOsModal(true)
		setIsPasswordModalOpen(false); 
		setIsReinstallModalVisible(false); 
		setIsTarifModalOpen(false);
		setIsScriptsModalOpen(true);
	}

	const handleButtonScriptClick = () => { 	
		const isRussian = lang === 'ru';

		const vdsId = location.state.serverId;

		if (selectedScriptId) {
	
			vdsActions('runscript', vdsId, {
				id: selectedScriptId
			})
			.then(result => {
				getVmById(vdsId)
				setShowOsModal(false)
				setIsPasswordModalOpen(false);
				setShowOsModalversion(false);
				setIsIPModalOpen(false);
				setIsScriptsModalOpen(false);
				setIsTarifModalOpen(false);
				setShowScriptButton(false);
			})
			.catch(error => {

				const title = isRussian ? 'Ошибка' : 'Error';
			
				showPopup({
					title,
					message: error.message
				}).then(buttonId => {
					console.log(buttonId)
				});
			
			});
		}
	}

	const handleScriptChangeClick = (scriptId) => {
		setSelectedScriptId(scriptId);
		showMainScriptButton();
	}

	const handleUpgradeButtonClick = () => {
		const isRussian = lang === 'ru';

		getTarifs(location.state.serverId)
		.then(response => {
			setTarifs(response);   

		})
		.catch(error => {

			const title = isRussian ? 'Ошибка' : 'Error';
		
			showPopup({
				title,
				message: error.message
			}).then(buttonId => {
				console.log(buttonId)
			});
		
		});


		setShowOsModal(true)
		setIsPasswordModalOpen(false); 
		setIsReinstallModalVisible(false); 
		setIsScriptsModalOpen(false);
		setIsTarifModalOpen(true);

		console.log(1212);
	}

	const handleModalTarifClick = () => {
		setShowOsModal(false);
		setIsPasswordModalOpen(false);
		setIsReinstallModalVisible(false);
		setShowIpButton(false);
		setIsTarifModalOpen(false);
		setShowTarifButton(false);
		setActiveBg({});
	}

	const handleModalPriceClick = (id) => {
		setActiveBg(prev => {
			// обнуляем значения для всех ключей
			const InitialState = tarifs.reduce((acc, tarif) => {
				acc[tarif.id] = ''; 
				return acc;
			}, {});
			
			// устанавливаем значение только для выбранного id
			return {
				...InitialState,  
				[id]: '#8e8e9399'
			}
		});

		setSelectedId(id); 
		console.log(id);
		showMainTarifptButton();
	}

	const handleButtonTarifClick = () => {
		const isRussian = lang === 'ru';

		upgradeTariff(location.state.serverId, {
			tariffId: selectedId 
		})
		.then(() => {
			console.log('Тариф успешно обновлен');
		})
		.catch((error) => {

			const title = isRussian ? 'Ошибка' : 'Error';
		
			showPopup({
				title,
				message: error.message
			}).then(buttonId => {
				console.log(buttonId)
			});
		});

		setShowOsModal(false)
		setIsTarifModalOpen(false);
		setShowTarifButton(false);
		setActiveBg({});
	}

	const buttonIpText = isPasswordEmpty 
	? (lang === 'ru' ? 'Введите новый пароль' : 'Enter New Password') 
	: (lang === 'ru' ? 'Переустановить OS' : 'Reinstall OS');

	const buttonScriptText = lang === 'ru' ? 'Запустить скрипт' : 'Run the script';

	const buttonTarifText = lang === 'ru' ? 'Купить новый тариф' : 'Buy a new tariff';

return (
	<div> 
    {showIpButton && (
      <MainButton  
				text={buttonIpText}  
				onClick={handleButtonIpClick}  
      />
  	)}
		{showTarifButton && (
      <MainButton  
				text={buttonTarifText}  
				onClick={handleButtonTarifClick}  
      />
  	)}
		{showScriptButton && (
      <MainButton  
				text={buttonScriptText}  
				onClick={handleButtonScriptClick}  
      />
  	)}
	{showOsModal && 
	<div className="modalSettings-container">
		{isTarifModalOpen && (
		<div className="modalUpgradeTarif">
		<div className="modalPlans-title">
			<span> 
				<LocaleText id="informationPage.tarif"/>
				</span> 
				<div className="modalPlans-backButton" onClick={handleModalTarifClick}>
					<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
						<g clip-path="url(#clip0_655_6470)">
							<path d="M1.78089 1.76499L11.311 11.3268M11.3268 1.78085L1.76504 11.3109" stroke="#8E8E93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</g>
						<defs>
							<clipPath id="clip0_655_6470">
								<rect width="13" height="13" fill="white"/>
							</clipPath>
						</defs>
					</svg>
				</div>
			</div>
		<div className="modalPlans-container">
				{tarifs.map(tarif => (
					<div 
						key={tarif.id}
						className={`modalPlan ${active ? 'active' : ''}`}
					>

						<div className={`modalPlan-cpu ${active ? 'active' : ''}`}>
							{active ? 'vCPU ' : 'vCPU:'}&nbsp;  
							<span className="modalPlan-cpu-amount">{tarif.cpu}&nbsp;</span>
						</div>

						<div className={`modalPlan-ram ${active ? 'active' : ''}`}>
							{active ? 'RAM ' : 'RAM:'}&nbsp;
							<span className="modalPlan-ram-amount">{tarif.ram}&nbsp;</span> 
						</div>

						<div className={`modalPlan-storage ${active ? 'active' : ''}`}>
							{active ? 'SSD ' : 'SSD:'}&nbsp;
							<span className="modalPlan-storage-amount">{tarif.disk}GB&nbsp;</span>
						</div>

						<div 
							className={`modalPlan-price-wrap ${active ? 'active' : ''}`}
							onClick={() => handleModalPriceClick(tarif.id)}
							style={{background: activeBg[tarif.id]}} 
							>
							<div className={`modalPlan-price ${active ? 'active' : ''}`}>
								{tarif.price} ₽
							</div>
						</div>

					</div>
				))}
			</div>
		</div>
		)}
		{isScriptsModalOpen && (
		<div className="modalScript-container"> 
			<div className="modalScript-title">
				<span> 
					<LocaleText id="settingsServer.scrioptTitle"/>
					</span> 
					<div className="modalScript-backButton" onClick={() => {
								handleModalSettingsClick();
						}}>
						<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
							<g clip-path="url(#clip0_655_6470)">
								<path d="M1.78089 1.76499L11.311 11.3268M11.3268 1.78085L1.76504 11.3109" stroke="#8E8E93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</g>
							<defs>
								<clipPath id="clip0_655_6470">
									<rect width="13" height="13" fill="white"/>
								</clipPath>
							</defs>
						</svg>
					</div>
				</div>
			<div className="scripts"> 
				{scripts.map(script => (
						<div 
						className="script"
						onClick={() => handleScriptChangeClick(script.id)}
						>
							<div className="nameScript">
								<span>{script.name}</span>
							</div>
							<div className="versionScript">
								<span>
								{script.desc.split('\n')[0].replace(/\.$/, '')}
								</span>
							</div>
							<div className="descriptionScript">
								<span>
									{script.desc.split('\n')[1]}  
								</span>
							</div>
						</div>
					))}
			</div>
		</div> 
		)}
	{isIPModalOpen && (
	<div className="modalPassword-container">
		<div className="modalPassword">
			<div className="modalPassword-title">
			<span> 
				<LocaleText id="settingsServer.ipTitle"/>
				</span> 
				<div className="modalPassword-backButton" onClick={handleModalIPClick}>
					<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
						<g clip-path="url(#clip0_655_6470)">
							<path d="M1.78089 1.76499L11.311 11.3268M11.3268 1.78085L1.76504 11.3109" stroke="#8E8E93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</g>
						<defs>
							<clipPath id="clip0_655_6470">
								<rect width="13" height="13" fill="white"/>
							</clipPath>
						</defs>
					</svg>
				</div>
			</div>
			<div className="IpSelect">
					<div 
						className="numberDecrementer"
						onClick={decrement}
						>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
							<path d="M1 6L11 6" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
						</svg>
					</div>
					<div className="number">
						<span>{count}</span>
					</div>
					<div 
					className="numberIncrementer"
					onClick={increment}  
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
							<g clip-path="url(#clip0_1157_9401)">
								<path d="M6 1L6 11" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
								<path d="M1 6L11 6" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
							</g>
							<defs>
								<clipPath id="clip0_1157_9401">
									<rect width="12" height="12" fill="white"/>
								</clipPath>
							</defs>
						</svg>
					</div>
				</div>
			<div 
				className={`applyButton ${error ? 'error' : ''}`}
				onClick={handleApplyIPClick}
			>
				<span>
					{errorMessage ? errorMessage : <LocaleText id="settingsServer.buy"/>}  
				</span>
				{errorMessage ? null : (
					<div className="numberPrice">
						{vm.ipv4_price * count}₽
					</div>
				)}
			</div>
		</div>
	</div>
	)}
		{isReinstallModalVisible && (
		<div className="modalReinstallOs-container">
		{showOsModalversion && 
        <div className="modal-container" onClick={() => setShowOsModalversion(false)} >
          <div className="modal">
            <div className="modal-title">
              <LocaleText id="buyPage.selectOs"/>
            </div>
          {selectedOs.items.map(version => (

            <div 
              className="modal-version"
              onClick={() => {
                handleVersionSelect(version);
                console.log('Выбрана версия:', version.name, ', ID:', version.id);

              }}
              style={{
                color: selectedVersion?.id === version.id ? 
                  'var(--tg-theme-text-color)' :
                  'var(--telegram-hint-color, #8E8E93)'
              }}
            >
              {version.name}
            </div>

          ))}       
         </div>  
        </div>  
      }
			<div className={`blur ${showOsModalversion ? 'active' : ''}`}>
			<div className="os-container">
				<div className="modalScript-title">
					<span> 
						<LocaleText id="buyPage.selectOs"/>
					</span> 
						<div className="modalPlans-backButton" onClick={() => {
								handleModalTarifClick();
						}}>
						<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
							<g clip-path="url(#clip0_655_6470)">
								<path d="M1.78089 1.76499L11.311 11.3268M11.3268 1.78085L1.76504 11.3109" stroke="#8E8E93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</g>
							<defs>
								<clipPath id="clip0_655_6470">
									<rect width="13" height="13" fill="white"/>
								</clipPath>
							</defs>
						</svg>
					</div>
				</div>
        <div className="os-options">

        {osList.map(os => (
          <div 
            className="os-option"
						style={selectedOs === os ? osStyle : null}
						onClick={() => handleOsClick(os)}
          >
          <div className="os-logo">
            {svgOSicons[os.type]}
            </div>
            <div className="os-name">{os.type}</div>
              <div className="os-version"> {selectedVersions[os.type]?.name} </div>
            </div>
          ))}
        </div>

      </div>
			<div className="newPassword">
				<input 
					spellCheck={false}
					class={`input ${isPasswordEmpty ? 'input-error' : ''}`}
					type="text"
					value={newPassword}
					placeholder='Password'
					onChange={handleChange} 
				/>
				<div
					className="generateЗassword"
					onClick={handleGeneratePassword}
				>
					<span>Cгенерировать</span>
				</div>
			</div>
		</div>
		</div>
		)}
	{isPasswordModalOpen && (
  	<div className="modalPassword-container">
			<div className="modalPassword">
				<div className="modalPassword-title">
				<span> 
						<LocaleText id="settingsServer.passwordTitle"/>
					</span> 
					<div className="modalPassword-backButton" onClick={handleModalPasswordClick}>
						<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
							<g clip-path="url(#clip0_655_6470)">
								<path d="M1.78089 1.76499L11.311 11.3268M11.3268 1.78085L1.76504 11.3109" stroke="#8E8E93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</g>
							<defs>
								<clipPath id="clip0_655_6470">
									<rect width="13" height="13" fill="white"/>
								</clipPath>
							</defs>
						</svg>
					</div>
				</div>
				<div className="modalPassword-input">
					<input
						style={{
							WebkitTapHighlightColor: 'transparent',
							textDecoration: 'none',
							outline: 'none'
						}}
						spellCheck={false}
						type="text"
						inputMode="text"
						value={password}
						onChange={handlePasswordChange}
						onPaste={handlePasswordChange}
						placeholder="Password" 
					/>
					<div className="promo-input">
						<span>{password}</span>
					</div>
				</div> 
				<div 
					className={`applyButton ${error ? 'error' : ''}`}
					onClick={handleApplyClick}
				>
					<span>
						{errorMessage ? errorMessage : <LocaleText id="profilePage.apply" />}  
					</span>
				</div>
			</div>
		</div>
		)}
	</div>
  }
	<BackButton onClick={handleBackClick} />
	<div className={`blur ${showOsModal ? 'active' : ''}`}>
		<div className="settingsServer-container">
			<div className="titleBlock">
				<div className="titleText">
					<LocaleText id="settingsServer.serverInformation"/>
				</div>
			</div>
			{vm &&
			<div className="serverDetails-container">
				<div 
				className="serverStatus"
				style={getServerStatusStyles(vm.state)}
				>
				{vm.statusText}
				</div>
				<div 
				class="info-and-instructions"
				style={{transform: `translateX(${translateX}%)`}}
				>
				<div className="serverInfo-container">

					<div className="LocationConfigSystemInfo">
						<div className="ID">
							ID:
							
							<span onClick={() => copyText(location.state?.serverId, 'id')}
								className={copiedId === 'id' ? 'copied' : ''}> 
								{location.state?.serverId}  
							</span>

						</div>
						<div className="location">
						<LocaleText id="settingsServer.location"/>:
							<span onClick={() => copyText(vm.tarif_line, 'line')}
								className={copiedId === 'line' ? 'copied' : ''}>
								{vm.tarif_line}
							</span>
						</div>
						<div className="configuration">
						<LocaleText id="settingsServer.сonfiguration"/>:
							<div className="configurationText">
								<div className="cpu">
									<span>
										- {vm.configuration.cpu}
									</span>
									vCPU
								</div>
								<div className="ram">
									<span>
										- {vm.configuration.ram}
									</span>
									GB RAM
								</div>
								<div className="ram">
									<span>
										- {vm.configuration.disk}
									</span>
									GB SSD
								</div>
							</div>
						</div>					
						<div className="os">
						<LocaleText id="settingsServer.system"/>:
							<span onClick={() => copyText(vm.os_name, 'os')}
								className={copiedId === 'os' ? 'copied' : ''}>
								{vm.os_name}
							</span>
						</div>
					</div>

					<div className="line">
					</div>

					<div className="SubscriptionInfo">
						<div className="activeTo">
							<LocaleText id="activeServersPage.activeTo"/>:
							<span onClick={() => copyText(vm.expires, 'activeTo')}
								className={copiedId === 'activeTo' ? 'copied' : ''}>
								{vm.expires}
							</span>
						</div>
						<div className="priceMonth">
						<LocaleText id="settingsServer.pricePerMonth"/>:
							<span onClick={() => copyText(
								vm.discount > 0 
									? (vm.price - (vm.price * vm.discount / 100)).toFixed(2).replace('.00', '')
									: vm.price, 
								'priceMonth'
							)}
							className={copiedId === 'priceMonth' ? 'copied' : ''}>
								{vm.discount > 0 ? (
									<>
										<div className="extend_discount">
											{ (vm.price - (vm.price * vm.discount / 100)).toFixed(2).replace('.00', '') }₽
										</div>
										<span className="dividerPrice">/</span>
										<span className="originalPrice">{vm.price}₽</span>
									</>
								) : (
									<>
										<span>{vm.price}₽ </span>
									</>
								)}
							</span>
						</div>
						<div className="autoRenew">
							<LocaleText id="activeServersPage.autoRenewal"/>:
							<span>
								{vm.autorenewal === 1 ? <LocaleText id="activeServersPage.gotIt"/> : <LocaleText id="activeServersPage.no"/>}  
							</span>
						</div>
					</div>

				</div>

				<div className="instructions-container">
					<div className="ip">
						IP:
						<span onClick={() => copyText(vm.ipv4, 'ip')}
							className={copiedId === 'ip' ? 'copied' : ''}>
							{vm.ipv4}
						</span>
					</div>
					<div className="userName">
					<LocaleText id="settingsServer.userName"/>:
						<span onClick={() => copyText(vm.login, 'userName')}
							className={copiedId === 'userName' ? 'copied' : ''}>
							{vm.login}
						</span>
					</div>
					<div className="password">
					<LocaleText id="settingsServer.password"/>:
						<span onClick={() => copyText(vm.password, 'password')}
							className={copiedId === 'password' ? 'copied' : ''}>
							{vm.password}
						</span>
					</div>
					{vm.ips && vm.ips.length > 0 && (
					<div className="line">
					</div>
					)}
					{vm.ips && vm.ips.length > 0 && (

					<div className="additionalIp">
						{vm.ips.map(ip => {
							const id = `ip-${ip}`;  
							return (
								<div className="ip" key={ip}>
									IP:
									<span
										onClick={() => copyText(ip, id)} 
										className={copiedId === id ? 'copied' : ''}
									>
										{ip}
									</span>
								</div>
							);
						})}
					</div>
					)}
				</div>
				</div>
				<div className="switcher">
				<div 
				className="information"
        ref={infoRef}
        onClick={handleInfoClick}>
				<LocaleText id="settingsServer.information"/>
      </div>
      <div
				className="instruction"
        ref={instructionRef}  
        onClick={handleInstructionClick}>
				<LocaleText id="settingsServer.instruction"/>
      </div>
      
      <div ref={highlightRef} className="highlighting"></div>
				</div>
			</div>
			}
			{vm && vm.state !== '1' && vm.state !== '6' && (
			<div className="titleBlocks">
				<div className="titleTexts">
					<LocaleText id="settingsServer.serverManagement"/>
				</div>
			</div>
			)}
			{vm && vm.state !== '1' && vm.state !== '6' && (
			<div className="systemSettings-container">
			<div 
          action="extend" 
          className="SystemSettingsButton"
          onClick={() => handleButtonClick('extend')}
        >
          <span>
						<LocaleText id="settingsServer.extendServer"/>
					</span>  
        </div>
				<div 
					action="autorenewal" 
					onClick={() => handleButtonClick('autorenewal')}
					className="SystemSettingsButton">

					<span>
					<LocaleText id="activeServersPage.autoRenewal"/>
					</span>
        </div>
				<div 
					id="changePasswordButton"
					style={getButtonStyles(vm.state, 'changePasswordButton')}
					className="SystemSettingsButton" 
					onClick={handlePasswordChangeClick}
				>
					<span
					id="changePasswordButton"
					style={getButtonStyles(vm.state, 'changePasswordButton')}
					>
						<LocaleText id="settingsServer.changePassword"/>
					</span>
				</div>
				<div 
					id="startButton"
					style={getButtonStyles(vm.state, 'startButton')}
	        action="start" 
          className="SystemSettingsButton"
          onClick={() => handleButtonClick('start')}
        >
          <span
					id="startButton"
					style={getButtonStyles(vm.state, 'startButton')}
					>
						<LocaleText id="settingsServer.run"/>
					</span>  
        </div>
        <div 
					id="rebootButton"
					style={getButtonStyles(vm.state, 'rebootButton')}
          action="reboot" 
          className="SystemSettingsButton"
          onClick={() => handleButtonClick('reboot')}
        >
          <span
					id="rebootButton"
					style={getButtonStyles(vm.state, 'rebootButton')}
					>
						<LocaleText id="settingsServer.reboot"/>
					</span>  
        </div>
				<div 
					id="stopButton"
					style={getButtonStyles(vm.state, 'stopButton')}
          action="stop" 
          className="SystemSettingsButton"
          onClick={() => handleButtonClick('stop')}
        >
          <span
					id="stopButton"
					style={getButtonStyles(vm.state, 'stopButton')}
					>
						<LocaleText id="settingsServer.stop"/>
					</span>  
        </div>
				<div 
					id="runButton"
					style={getButtonStyles(vm.state, 'runButton')}
					className="SystemSettingsButton"
					onClick={handleRunButtonClick}
				>
					<span
						id="runButton"
						style={getButtonStyles(vm.state, 'runButton')}
					>
					<LocaleText id="settingsServer.runTheScript"/>
					</span>
        </div>
				<div 
				id="ipButton"
				style={getButtonStyles(vm.state, 'ipButton')}
				className="SystemSettingsButton"
				onClick={handleAddIpChangeClick}
				>
					<span
					id="ipButton"
					style={getButtonStyles(vm.state, 'ipButton')}
					>
						<LocaleText id="settingsServer.addIp"/>
					</span>
        </div>
				<div 
				id="reinstalButton"
				style={getButtonStyles(vm.state, 'reinstalButton')}
				className="SystemSettingsButton"
				onClick={handleReinstallOs}
				>
					<span
					id="reinstalButton"
					style={getButtonStyles(vm.state, 'reinstalButton')}
					>
						<LocaleText id="settingsServer.reinstallOs"/>
					</span>
        </div>
				<div 
					id="upgradeTarifButton"
					onClick={handleUpgradeButtonClick} 
					style={getButtonStyles(vm.state, 'upgradeTarifButton')}
					className="SystemSettingsButton"
				>
					<span
					id="upgradeTarifButton"
					style={getButtonStyles(vm.state, 'upgradeTarifButton')}
					>
					<LocaleText id="settingsServer.configurationImprovement"/>
					</span>
        </div>
			</div>
			)}
		</div>
	</div>
	</div>
  );
};

export default SettingsServer;